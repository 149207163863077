import { Component, inject } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { MessageService } from 'primeng/api';
import { UserInterface } from 'voxr-shared';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { InputSwitchModule } from 'primeng/inputswitch';
import { RippleModule } from 'primeng/ripple';

@Component({
  selector: 'app-transfer-availability',
  standalone: true,
  imports: [CommonModule, FormsModule, ButtonModule, OverlayPanelModule, InputTextModule, InputSwitchModule, RippleModule],
  templateUrl: './transfer-availability.component.html',
  styleUrl: './transfer-availability.component.scss'
})
export class TransferAvailabilityComponent {
  private userService = inject(UserService);
  private messageService = inject(MessageService);

  user?: UserInterface | null;
  name: string = ' ';
  loading: boolean = false;

  constructor() {
    this.userService.user$.pipe(takeUntilDestroyed()).subscribe(user => this.loadUser(user));
  }

  private loadUser(user?: UserInterface | null) {
    this.user = user;
    if (this.user) {
      if (!this.user.agentInfo) this.user.agentInfo = {};
      if (!this.user.agentInfo.liveTransfer) this.user.agentInfo.liveTransfer = { available: false, phone: this.user.phone };
    }

  }

  async setAvailability() {
    this.loading = true;
    await this.userService.setTransferAvalability(this.user!.agentInfo!.liveTransfer!);
    this.messageService.add({severity: 'success', summary: 'Success', detail: 'Updated live transfer availability.'});
    this.loading = false;
    
  }

}
