@if(user) {
<div pRipple class="tw-flex tw-gap-2 tw-p-1" (click)="statusPanel.toggle($event)"
  class="tw-cursor-pointer hover:surface-main" [ngClass]="{'tw-animate-pulse': loading}">
  @if(user.agentInfo!.liveTransfer!.available) {
  <i class="pi pi-headphones text-success tw-text-lg tw-font-bold"></i> Available for live transfer
  ({{user.agentInfo!.liveTransfer!.phone}})
  } @else {

  <i class="pi pi-headphones text-danger tw-text-lg tw-font-bold"></i> Not available for live transfer
  }
</div>

<p-overlayPanel #statusPanel appendTo="body">
  <form #transferForm="ngForm" class="uw-fields-container" [ngClass]="{'tw-animate-pulse': loading}">
    <div class="tw-flex tw-items-center tw-gap-2">
      <p-inputSwitch name="backchanneling" id="backchanneling" (onChange)="setAvailability()"
        [(ngModel)]="user.agentInfo!.liveTransfer!.available"></p-inputSwitch>
      <label for="backchanneling">Available for live transfer</label>
    </div>
    @if(user.agentInfo!.liveTransfer!.available) {
    <div class="p-fluid p-float-label">
      <input pInputText id="phone" name="phone" [(ngModel)]="user.agentInfo!.liveTransfer!.phone"
        (change)="setAvailability()" />
      <label for="phone">Transfer Destination</label>
    </div>

    }
    <!-- <div class="uw-sticky-bottom-toolbar">
      <p-button label="Set Transfer Availability" severity="primary" (click)="setAvailability()" [loading]="loading"
        [disabled]="transferForm.invalid"></p-button>
    </div> -->

  </form>
</p-overlayPanel>

}