<div class="tw-flex tw-h-screen tw-w-screen">

  <!-- sidebar -->
  <div class="uw-sidebar">
    <a class="tw-flex tw-items-center tw-justify-center tw-h-16 " routerLink="/">
      <img src="assets/images/logo/voxr-logotype.png" alt="Voxr" class="tw-h-10">
    </a>
    <div class="tw-flex tw-flex-col tw-flex-1 tw-overflow-y-auto">
      <nav class="tw-flex tw-flex-col tw-gap-1 tw-flex-1 tw-py-2 ">
        <a routerLink="/dashboard" class="">
          <i class="pi pi-home"></i> Dashboard
        </a>
        <a routerLink="/contacts" routerLinkActive="uw-active" class="">
          <i class="pi pi-users"></i> Contacts
        </a>
        @if(roles?.includes('admin') || roles?.includes('owner')) {
        <a routerLink="/assistants" routerLinkActive="uw-active" class="">
          <i class="pi pi-bolt"></i> Assistants
        </a>
        <a routerLink="/campaigns" routerLinkActive="uw-active" class="">
          <i class="pi pi-chart-line"></i> Campaigns
        </a>
        <a routerLink="/calls" routerLinkActive="uw-active" class="">
          <i class="pi pi-phone"></i> Call Logs
        </a>
        <div class="tw-flex-grow"></div>
        <a routerLink="/blueprints" routerLinkActive="uw-active" class="">
          <i class="pi pi-book"></i> Blueprints
        </a>
        <!-- <a routerLink="/schedules" routerLinkActive="uw-active"
          class="">
          <i class="pi pi-calendar"></i> Schedules
        </a> -->
        <a routerLink="/integration" routerLinkActive="uw-active" class="">
          <i class="pi pi-cog"></i> Integrations
        </a>
        <a routerLink="/workspace/members" routerLinkActive="uw-active" class="">
          <i class="pi pi-users"></i> Workspace Members
        </a>
        <a routerLink="/workspace/settings" routerLinkActive="uw-active" class="">
          <i class="pi pi-cog"></i> Workspace Settings
        </a>
        }
      </nav>
      <div class="tw-mt-auto tw-p-1 tw-text-xs text-warning">
        {{serverId|json}}
      </div>
      @if(workspaces) {
      <div class="p-fluid">
        <p-dropdown [options]="workspaces" optionValue="_id" optionLabel="name" placeholder="Select Workspace"
          [ngModel]="workspace?._id" (onChange)="setWorkspace($event.value)"></p-dropdown>
      </div>
      }

    </div>
  </div>

  <!-- Main content -->
  <div class="tw-flex tw-flex-col tw-flex-1 tw-flex-grow tw-min-w-0">
    <div class="tw-flex tw-items-center tw-justify-between tw-h-16 surface-main-darker">
      <div class="tw-flex tw-items-center tw-px-4">
        @if(workspace) {<span>{{workspace.name}}</span>}
      </div>
      <app-transfer-availability />
      <div class="tw-flex tw-gap-2 tw-items-center tw-pr-4">
        @if(user) {
        <app-user-status />
        <p-button icon="pi pi-sign-out" [text]="true" (click)="logout()"></p-button>
        } @else {
        <a routerLink="/login">Login</a>
        }
      </div>
    </div>
    <div class="tw-flex-1 tw-overflow-auto tw-pt-4 tw-px-4">
      <router-outlet />
    </div>
  </div>